import Direction from "./Direction";
import Position from "./Position";

class Item {

    private readonly _id: string;
    private readonly _position: Position;
    private readonly _direction: Direction;

    protected constructor(id: string, position: Position, direction: Direction) {
        this._id = id;
        this._position = position
        this._direction = direction;
    }

    public static fromData(itemData: ItemData): Item {
        return new Item(
            itemData.id,
            Position.fromData(itemData.position),
            Direction.fromName(itemData.direction)
        );
    }

    get id(): string {
        return this._id;
    }

    get direction(): Direction {
        return this._direction;
    }
    get position(): Position {
        return this._position;
    }

    get engagingPosition(): Position {
        let engagingCol = this.position.col + this.direction.colDelta;
        let engagingRow = this.position.row + this.direction.rowDelta;
        return new Position(engagingCol, engagingRow);
    }

    get engagingDirection(): Direction {
        return Direction.fromPositions(this.engagingPosition, this.position);
    }

    withDirection(direction: Direction): Item {
        return new Item(this.id, this.position, direction);
    }

    withPosition(position: Position): Item {
        return new Item(this.id, position, this.direction);
    }

}

export default Item;