import React, {createContext, useContext, useEffect, useState} from "react";
import Item from "../model/Item";
import Npc from "../model/Npc";
import Area from "../model/Area";
import Board from "./Board";
import Door from "../model/Door";
import Fetcher from "../data/Fetcher";
import {BackgroundContext} from "./Background";
import Position from "../model/Position";
import {GameContext} from "./Game";

interface LevelContextType {
    hero: Item
    npcs: Npc[],
    doors: Door[],
    areas: Area[],
    numCols: number,
    numRows: number,
    avatarSize: number,
    avatarSpeed: number,
    getLeft: (position: Position) => number;
    getTop: (position: Position) => number;
    getCoordinate: (pos: number) => number;
}

export const LevelContext = createContext<LevelContextType | undefined>(undefined);

const Level = () => {

    const {backgroundWidth} = useContext(BackgroundContext)!;
    const {levelId} = useContext(GameContext)!;

    const [levelData, setLevelData] = useState<LevelData | undefined>();

    useEffect(() => {
        new Fetcher<LevelData>("/data/levels/" + levelId + ".json")
            .onSuccess((levelData: LevelData) => setLevelData(levelData))
            .fetch();
    }, [levelId]);

    if (!levelData) {
        return;
    }

    const backgroundImage = levelData.backgroundImage;

    const hero = Item.fromData(levelData.hero);
    const npcs = levelData.npcs.map(npcData => Npc.fromData(npcData));
    const doors = levelData.doors.map(doorData => Door.fromData(doorData));
    const areas = levelData.areas.map(areaData => Area.fromData(areaData));
    const numCols = levelData.numCols;
    const numRows = levelData.numRows;
    const cellSize = backgroundWidth / numCols;
    const avatarSize = cellSize * 2;
    const avatarSpeed = (cellSize * 10);
    const leftOffset = (avatarSize - cellSize) / 2;
    const topOffset = avatarSize - cellSize;

    const getLeft = (position: Position): number => {
        return (position.col * cellSize) - leftOffset;
    }

    const getTop = (position: Position): number => {
        return (position.row * cellSize) - topOffset;
    }

    const getCoordinate = (pos: number): number => {
        return Math.floor(pos / cellSize)
    }

    const levelContext = {
        hero: hero,
        npcs: npcs,
        doors: doors,
        areas: areas,
        numCols: numCols,
        numRows: numRows,
        avatarSize: avatarSize,
        avatarSpeed: avatarSpeed,
        getLeft: getLeft,
        getTop: getTop,
        getCoordinate: getCoordinate
    }

    console.log("LEVEL")

    return (
        <LevelContext.Provider value={levelContext}>
            <div className={"Level"} style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <Board/>
            </div>
        </LevelContext.Provider>
    );

}

export default Level;