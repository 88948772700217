import React, {useContext, useEffect, useState} from "react";
import Images from "../model/Images";
import {BackgroundContext} from "./Background";
import Npc from "../model/Npc";

interface DialogLayerProps {
    npc: Npc;
    onDialogFinish: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Dialog = (props: DialogLayerProps) => {

    const {backgroundWidth} = useContext(BackgroundContext)!;

    const intervalWait = 30;
    const dialogFrameImage = Images.getInstance().dialogFrameImage;
    const dialogFrameWidth = backgroundWidth * 0.8;
    const dialogAspectRatio = dialogFrameImage.width / dialogFrameImage.height;
    const dialogs = props.npc.dialogs;

    const [text, setText] = useState<string>("");
    const [lines, setLines] = useState<string[]>([]);
    const [dialogIndex, setDialogIndex] = useState<number>(0);
    const [printButton, setPrintButton] = useState<boolean>(false);

    const printText = (dialogLines: string[]) => {
        let lineIndex = 0;
        let letterIndex = 0;
        const intervalId = setInterval(() => {
            const currentLine = dialogLines[lineIndex];
            setText(currentLine.substring(0, letterIndex + 1));
            if (letterIndex === currentLine.length) {
                if (lineIndex + 1 === dialogLines.length) {
                    setPrintButton(true);
                    clearInterval(intervalId);
                    return;
                }
                setLines((prevItems) => [...prevItems, currentLine]);
                setText("");
                letterIndex = 0;
                lineIndex++;
            }
            letterIndex++;
        }, intervalWait);
    }

    useEffect(() => {
        printText(dialogs.get(dialogIndex)!);
    }, []);

    const dialogFrameStyle = () => {
        return {
            aspectRatio: dialogAspectRatio,
            width: dialogFrameWidth,
            backgroundImage: "url(" + dialogFrameImage.src + ")"
        };
    }

    const dialogTextStyle = () => {
        return {
            aspectRatio: dialogAspectRatio
        };
    }

    const button = () => {
        if (!printButton) {
            return "";
        }
        let image = Images.getInstance().dialogCloseImage;
        let onClick = props.onDialogFinish;
        if (dialogs.size !== 1 && dialogIndex + 1 !== dialogs.size) {
            image = Images.getInstance().dialogNextImage;
            onClick = () => {
                setPrintButton(false);
                const newDialogIndex = dialogIndex + 1;
                printText(dialogs.get(newDialogIndex)!);
                setText("");
                setLines([])
                setDialogIndex(newDialogIndex)
            }
        }
        return <div style={{backgroundImage: "url(" + image + ")"}} className={"DialogButton"} onClick={onClick}/>
    }

    return (
        <div className={"Dialog"}>
            <div style={dialogFrameStyle()} className={"DialogFrame"}>
                <div style={dialogTextStyle()} className={"DialogText"}>
                    {lines.map((line, index) => <p key={index}>{line}</p>)}
                    <p>{text}</p>
                </div>
                {button()}
            </div>
        </div>
    );


}

export default Dialog;