import Direction from "./Direction";
import Position from "./Position";
import Item from "./Item";

class Npc extends Item {

    private readonly _dialogs: Map<number, string[]>;

    private constructor(id: string, position: Position, direction: Direction, dialogs: Map<number, string[]>) {
        super(id, position, direction);
        this._dialogs = dialogs;
    }

    public static fromData(npcData: NpcData): Npc {
        const dialogs = new Map<number, string[]>();
        for (const order in npcData.dialogs) {
            dialogs.set(Number(order), npcData.dialogs[order])
        }
        return new Npc(
            npcData.id,
            Position.fromData(npcData.position),
            Direction.fromName(npcData.direction),
            dialogs
        );
    }

    get dialogs(): Map<number, string[]> {
        return this._dialogs;
    }
}

export default Npc;