import Position from "./Position";

class Area {

    private readonly _position: Position
    private readonly _width: number;
    private readonly _height: number;

    private constructor(
        position: Position,
        width: number,
        height: number,
    ) {
        this._position = position;
        this._width = width;
        this._height = height;
    }

    public static fromData(areaData: AreaData): Area {
        return new Area(
            Position.fromData(areaData.position),
            areaData.width,
            areaData.height
        );
    }

    get position(): Position {
        return this._position;
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    isInside(position: Position): boolean {
        const areaCol = this.position.col;
        const areaRow = this.position.row;
        const insideCol = position.col >= areaCol && position.col <= (areaCol + this.width - 1);
        const insideRow = position.row >= areaRow && position.row <= (areaRow + this.height - 1);
        return insideCol && insideRow;
    }

}

export default Area;