class Images {

    private static instance: Images;

    private readonly _buffer: Set<string>;
    private readonly _imagesBySrc: Map<string, HTMLImageElement>;
    private readonly _dialogFrameImage: string;
    private readonly _dialogNextImage: string;
    private readonly _dialogCloseImage: string;

    private constructor(
        dialogFrameImage: string,
        dialogNextImage: string,
        dialogCloseImage: string
    ) {
        this._buffer = new Set<string>;
        this._imagesBySrc = new Map<string, HTMLImageElement>();
        this._dialogFrameImage = dialogFrameImage;
        this._dialogNextImage = dialogNextImage;
        this._dialogCloseImage = dialogCloseImage;
        this._buffer.add(dialogFrameImage);
        this._buffer.add(dialogNextImage);
        this._buffer.add(dialogCloseImage);
    }

    public static init(
        dialogFrameImage: string,
        dialogNextImage: string,
        dialogCloseImage: string
    ) {
        Images.instance = new Images(
            dialogFrameImage,
            dialogNextImage,
            dialogCloseImage
        );
        return Images.instance;
    }

    public static getInstance(): Images {
        return Images.instance;
    }

    public add(imageSrc: string): void {
        this._buffer.add(imageSrc);
    }

    public load(): Promise<void> {
        const promises = Array.from(this._buffer).map(imageJson => this.imagePromise(imageJson));
        return Promise.all(promises)
            .then(loadedImages => {
                loadedImages.forEach(loadedImage => this._imagesBySrc.set(loadedImage.src, loadedImage.image));
            });
    }

    private imagePromise(imageSrc: string): Promise<{ src: string, image: HTMLImageElement }> {
        return new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = imageSrc;
            newImage.onload = () => resolve({src: imageSrc, image: newImage});
            newImage.onerror = reject;
        });
    }

    public get(imageSrc: string): HTMLImageElement {
        const image = this._imagesBySrc.get(imageSrc);
        if (!image) {
            throw new Error("Unable to find loaded image " + imageSrc);
        }
        return image;
    }

    get dialogFrameImage(): HTMLImageElement {
        return this.get(this._dialogFrameImage);
    }

    get dialogNextImage(): string {
        return this._dialogNextImage;
    }

    get dialogCloseImage(): string {
        return this._dialogCloseImage;
    }
}

export default Images;