import Status from "./Status";
import Direction from "./Direction";
import Position from "./Position";

class Sprite {

    private readonly _baseWidth: number;
    private readonly _baseHeight: number;
    private readonly _frameTime: number;
    private readonly _sequences: Map<Status, Map<Direction, Position[]>>;

    private constructor(
        baseWidth: number,
        baseHeight: number,
        frameTime: number,
        sequences: Map<Status, Map<Direction, Position[]>>,
    ) {
        this._baseWidth = baseWidth;
        this._baseHeight = baseHeight;
        this._frameTime = frameTime;
        this._sequences = sequences;
    }

    public static fromData(spriteData: SpriteData): Sprite {
        const sequences = new Map<Status, Map<Direction, Position[]>>();
        for (const status in spriteData.sequences) {
            const directionMap = new Map<Direction, Position[]>();
            for (const direction in spriteData.sequences[status]) {
                const sequenceJson = spriteData.sequences[status][direction];
                const start = Position.fromData(sequenceJson.start);
                const end = Position.fromData(sequenceJson.end);
                directionMap.set(Direction.fromName(direction), this.getPositionsInBetween(start, end));
            }
            sequences.set(Status.fromName(status), directionMap);
        }
        return new Sprite(
            spriteData.baseWidth,
            spriteData.baseHeight,
            spriteData.frameTime,
            sequences
        );
    }

    private static getPositionsInBetween(start: Position, end: Position): Position[] {
        const positions = [];
        if (start.col === end.col) {
            const startY = Math.min(start.row, end.row);
            const endY = Math.max(start.row, end.row);
            for (let y = startY; y <= endY; y++) {
                positions.push(new Position(start.col, y));
            }
        } else if (start.row === end.row) {
            const startX = Math.min(start.col, end.col);
            const endX = Math.max(start.col, end.col);
            for (let x = startX; x <= endX; x++) {
                positions.push(new Position(x, start.row));
            }
        }
        return positions;
    }

    get baseWidth(): number {
        return this._baseWidth;
    }

    get baseHeight(): number {
        return this._baseHeight;
    }

    get frameTime(): number {
        return this._frameTime;
    }

    getSequence(printableStatus: Status, direction: Direction): Position[] {
        const sequence = this._sequences.get(printableStatus)?.get(direction);
        if (!sequence) {
            throw new Error("Positions not found for status " + printableStatus + " and direction " + direction)
        }
        return sequence;
    }

}

export default Sprite;