import Position from "../model/Position";
import Direction from "../model/Direction";
import Area from "../model/Area";

export function shortestPath(
    start: Position,
    targetPosition: Position,
    areas: Area[],
    numCols : number,
    numRows: number): Position[] {

    // Check if a given position is within bounds and not blocked by any area
    function isValidPosition(position: Position): boolean {
        if (position.col < 0 || position.col >= numCols || position.row < 0 || position.row >= numRows) return false;
        // Check if the position is within any of the blocked areas
        for (const area of areas) {
            const areaPosition = area.position;
            if (
                position.col >= areaPosition.col && position.col < areaPosition.col + area.width &&
                position.row >= areaPosition.row && position.row < areaPosition.row + area.height
            ) {
                return false;
            }
        }
        return true;
    }

    // Check if a diagonal move is valid (not crossing blocked area corners)
    function isDiagonalMoveValid(from: Position): boolean {
        // Get the corner coordinates of the blocked areas
        for (const area of areas) {
            const areaPosition = area.position;
            const corners = [
                new Position(areaPosition.col, areaPosition.row),
                new Position(areaPosition.col + area.width - 1, areaPosition.row),
                new Position(areaPosition.col, areaPosition.row + area.height - 1),
                new Position(areaPosition.col + area.width - 1, areaPosition.row + area.height - 1)
            ];

            // Check if diagonal move crosses any corner
            if (Direction.VALUES.some(dir => {
                if (dir.colDelta !== 0 && dir.rowDelta !== 0) {
                    // Check if diagonal move would cross any corner
                    const corner1 = new Position(from.col + dir.colDelta, from.row);
                    const corner2 = new Position(from.col, from.row + dir.rowDelta);
                    return corners.some(corner => corner.samePosition(corner1) || corner.samePosition(corner2));
                }
                return false;
            })) {
                return false;
            }
        }
        return true;
    }

    // Heuristic: Manhattan distance
    function manhattanDist(pos: Position, goal: Position) {
        return Math.abs(pos.col - goal.col) + Math.abs(pos.row - goal.row);
    }

    // A* search algorithm
    function aStar(start: Position, goal: Position): Position[] | null {
        const openSet = new Set<Position>();
        const cameFrom = new Map();
        const gScore = Array.from({length: numRows}, () => Array(numCols).fill(Infinity));
        const fScore = Array.from({length: numRows}, () => Array(numCols).fill(Infinity));

        gScore[start.row][start.col] = 0;
        fScore[start.row][start.col] = manhattanDist(start, goal);

        openSet.add(start);

        while (openSet.size > 0) {
            // Get the node in openSet with the lowest fScore value
            let current: Position | undefined
            openSet.forEach(pos => {
                if (!current || fScore[pos.row][pos.col] < fScore[current.row][current.col]) {
                    current = pos;
                }
            });

            if (!current) {
                throw new Error("Error at getting path")
            }
            if (current.col === goal.col && current.row === goal.row) {
                return reconstructPath(cameFrom, current);
            }
            openSet.delete(current);

            for (const dir of Direction.VALUES) {

                const neighbor = new Position(current.col + dir.colDelta, current.row + dir.rowDelta);

                if (!isValidPosition(neighbor)) continue;

                // Disallow diagonal moves through corners of blocked areas
                if (dir.colDelta !== 0 && dir.rowDelta !== 0 && !isDiagonalMoveValid(current)) continue;

                const tentative_gScore = gScore[current.row][current.col] + 1;

                if (tentative_gScore < gScore[neighbor.row][neighbor.col]) {
                    cameFrom.set(getCoordinatesKey(neighbor), current);
                    gScore[neighbor.row][neighbor.col] = tentative_gScore;
                    fScore[neighbor.row][neighbor.col] = gScore[neighbor.row][neighbor.col] + manhattanDist(neighbor, goal);
                    openSet.add(neighbor);
                }
            }
        }
        return null; // No path found
    }

    // Reconstruct path from cameFrom map
    function reconstructPath(cameFrom: Map<string, Position>, current: Position) {
        const totalPath = [];

        totalPath.push(current);

        while (cameFrom.has(getCoordinatesKey(current))) {
            const step = cameFrom.get(getCoordinatesKey(current));
            if (!step) {
                throw new Error("Unexpected error at preparing path")
            }
            totalPath.unshift(step);
            current = step;
        }

        if (totalPath.length > 0) {
            totalPath[totalPath.length - 1] = targetPosition;
        }

        return totalPath;
    }

    function getCoordinatesKey(coordinate: Position): string {
        return (coordinate.col + "-" + coordinate.row)
    }

    function simplifyPath(path: Position[]): Position[] {
        if (path.length <= 1) return path;

        let simplified = [path[0]]; // Always keep the first point

        for (let i = 1; i < path.length - 1; i++) {
            let prev = simplified[simplified.length - 1];  // Last point in the simplified path
            let current = path[i];                         // Current point
            let next = path[i + 1];                        // Next point

            // Check if current is redundant by seeing if it lies in a straight line between prev and next
            let isStraightLine = (next.row - current.row) * (current.col - prev.col) ===
                (next.col - current.col) * (current.row - prev.row);

            if (!isStraightLine) {
                simplified.push(current); // Add current point if it's not in a straight line
            }
        }

        // Always keep the last point
        const last = path[path.length - 1]
        simplified.push(last);
        return simplified.slice(1);
    }

    const path = aStar(start, targetPosition);
    if (path) {
        return simplifyPath(path);
    }
    return [start];

}