import Position from "./Position";

class Door {

    private readonly _position: Position
    private readonly _levelId: string;

    private constructor(
        position: Position,
        levelId: string
    ) {
        this._position = position;
        this._levelId = levelId;
    }

    public static fromData(doorData: DoorData): Door {
        return new Door(
            Position.fromData(doorData.position),
            doorData.levelId
        );
    }

    get position(): Position {
        return this._position;
    }

    get levelId(): string {
        return this._levelId;
    }
}

export default Door;