import Position from "./Position";

class Direction {

    private readonly _name: string;
    private readonly _colDelta: number;
    private readonly _rowDelta: number;

    private constructor(name: string, colDelta: number, rowDelta: number) {
        this._name = name;
        this._colDelta = colDelta;
        this._rowDelta = rowDelta;
    }

    public static TOP = new Direction("top", 0, -1);
    public static BOTTOM = new Direction("bottom", 0, 1);
    public static LEFT = new Direction("left", -1, 0);
    public static RIGHT = new Direction("right", 1, 0);
    public static TOP_LEFT = new Direction("top-left", -1, -1);
    public static TOP_RIGHT = new Direction("top-right", 1, -1);
    public static BOTTOM_LEFT = new Direction("bottom-left", -1, 1);
    public static BOTTOM_RIGHT = new Direction("bottom-right", 1, 1);

    public static VALUES = [
        Direction.TOP,
        Direction.BOTTOM,
        Direction.LEFT,
        Direction.RIGHT,
        Direction.TOP_LEFT,
        Direction.TOP_RIGHT,
        Direction.BOTTOM_LEFT,
        Direction.BOTTOM_RIGHT
    ];

    get name(): string {
        return this._name;
    }

    get colDelta(): number {
        return this._colDelta;
    }

    get rowDelta(): number {
        return this._rowDelta;
    }

    static fromName(name: string): Direction {
        const direction = this.VALUES.find(direction => direction.name === name);
        if (!direction) {
            throw new Error("Direction not found for " + name);
        }
        return direction;
    }

    static fromPositions(origin: Position, target: Position): Direction {
        const colDelta = Math.max(-1, Math.min(1, target.col - origin.col));
        const rowDelta = Math.max(-1, Math.min(1, target.row - origin.row));
        const direction = this.VALUES.find(direction => direction.rowDelta === rowDelta && direction.colDelta === colDelta);
        if (!direction) {
            throw new Error("Direction not found for given positions");
        }
        return direction;
    }

}


export default Direction;