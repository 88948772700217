import React, {useContext, useEffect, useRef, useState, useTransition} from "react";
import Position from "../model/Position";
import Status from "../model/Status";
import Direction from "../model/Direction";
import {shortestPath} from "../services/Pathfinder";
import Frame from "./Frame";
import {GameContext} from "./Game";
import Item from "../model/Item";
import {LevelContext} from "./Level";

interface ItemProps {
    item: Item,
    onWalkFinish: () => void;
}

const Walker = (props: ItemProps) => {

    const {getAvatar, sprite} = useContext(GameContext)!;
    const {areas, numCols, numRows, avatarSpeed, getLeft, getTop} = useContext(LevelContext)!;
    const itemRef = useRef<HTMLDivElement | null>(null);

    //constants
    const item = props.item;
    const images = getAvatar(item.id).images;

    //walking states
    const [path, setPath] = useState<Position[]>([]);
    const [currentPosition, setCurrentPosition] = useState<Position>(item.position);
    const [currentDirection, setCurrentDirection] = useState<Direction>(item.direction);
    const [currentStatus, setCurrentStatus] = useState<Status>(Status.IDLE);
    const [transition, setTransition] = useState<string>("none");

    useEffect(() => {
        const nextPosition = item.position;
        if (currentPosition.samePosition(nextPosition)) {
            setCurrentDirection(item.direction);
            return;
        }
        const path = shortestPath(currentPosition, nextPosition, areas, numCols, numRows);
        setCurrentStatus(Status.WALKING);
        setPath(path);
    }, [item]);

    useEffect(() => {
        if (path.length === 0) {
            return;
        }
        const nextPosition = path[0];
        const direction = Direction.fromPositions(currentPosition, nextPosition);
        const transition = getTransition(nextPosition);
        setCurrentPosition(nextPosition);
        setCurrentDirection(direction);
        setTransition(transition);
        const onTransitioned = () => {
            setPath(path.slice(1));
            if (path.length === 1) {
                setCurrentStatus(Status.IDLE);
                setCurrentDirection(item.direction)
                setCurrentPosition(item.position);
                setTransition("none");
                props.onWalkFinish();
            }
        }
        itemRef.current!.addEventListener('transitionend', onTransitioned);
        return () => {
            itemRef.current!.removeEventListener('transitionend', onTransitioned);
        };
    }, [path]);


    const getTransition = (targetPosition: Position) => {
        const deltaX = getLeft(targetPosition) - currentLeft;
        const deltaY = getTop(targetPosition) - currentTop;
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
        const time = distance / avatarSpeed;
        return "left " + time + "s linear, top " + time + "s linear";
    }

    const currentLeft = getLeft(currentPosition);
    const currentTop = getTop(currentPosition);

    return (
        <div ref={itemRef} className={"Item"} style={{
            left: currentLeft,
            top: currentTop,
            transition: transition
        }}>
            <Frame images={images} status={currentStatus} direction={currentDirection}/>
        </div>
    )
}

export default Walker;