import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import "./main.scss"
import ScreenLoader from "./components/ScreenLoader";

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/screen" element={<ScreenLoader/>}/>
                <Route path="/sandbox" element={<ScreenLoader sandbox={true}/>}/>
            </Routes>
        </BrowserRouter>
    )

};

export default App;