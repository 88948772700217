class Position {

    protected readonly _col: number;
    protected readonly _row: number;

    public constructor(col: number, row: number) {
        this._col = col;
        this._row = row;
    }

    public static fromData(positionData: PositionData): Position {
        return new Position(
            positionData.col,
            positionData.row
        );
    }

    get col(): number {
        return this._col;
    }

    get row(): number {
        return this._row;
    }

    samePosition(position: Position) {
        return this._col === position._col && this._row === position._row;
    }

}

export default Position;