import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Game from "./Game";
import Background from "../components/Background";

interface ItemLayerProps {
    sandbox?: boolean;
}

const ScreenLoader = (props: ItemLayerProps) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);

    const fullScreenChangeEventKeys = [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange"
    ];

    const fullScreenRequestKeys = [
        "requestFullscreen",
        "webkitRequestFullscreen",
        "mozRequestFullScreen",
        "msRequestFullscreen"
    ];

    const exitFullScreenRequestKeys = [
        "exitFullscreen",
        "webkitExitFullscreen",
        "mozCancelFullScreen",
        "msExitFullscreen"
    ];

    useEffect(() => {
        if (props.sandbox) {
            setLoading(false);
            return;
        }
        openFullScreen();
        fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
            document.addEventListener(fullScreenChangeEvent, handleFullScreenChangeEvent)
        );
        return () => {
            fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
                document.removeEventListener(fullScreenChangeEvent, handleFullScreenChangeEvent)
            );
        }
    }, []);

    const openFullScreen = () => {
        const element = document.documentElement as any;
        for (const fullScreenRequestKey of fullScreenRequestKeys) {
            if (fullScreenRequestKey in element) {
                element[fullScreenRequestKey]()
                    .then(() => {
                        if (window.innerWidth <= 768) {
                            const screenOrientation = screen.orientation as any;
                            screenOrientation["lock"]("landscape").catch(() => {
                            });
                        }
                    })
                    .catch((err: Error) => {
                        console.error("Unable to enter full screen", err);
                    });
                break;
            }
        }
    }

    const handleFullScreenChangeEvent = () => {
        if (document.fullscreenElement) {
            setLoading(false);
        } else {
            finishCloseFullScreen();
        }
    };

    const closeFullScreen = () => {
        if (props.sandbox) {
            finishCloseFullScreen();
            return;
        }
        const anyDocument = document as any;
        for (const exitFullScreenRequestKey of exitFullScreenRequestKeys) {
            if (exitFullScreenRequestKey in anyDocument) {
                anyDocument[exitFullScreenRequestKey]()
                    .then(() => finishCloseFullScreen())
                    .catch((err: Error) => {
                        console.error("Unable to close full screen", err);
                    });
                break;
            }
        }
    }

    const finishCloseFullScreen = () => {
        screen.orientation.unlock();
        navigate("/");
    }

    if (loading) {
        return;
    }

    return (
        <div className={"Screen"}>
            <div className={"CloseButton"}>
                <button onClick={closeFullScreen}>Close</button>
            </div>
            <Background>
                <Game/>
            </Background>
        </div>
    )
}

export default ScreenLoader;