import React, {useContext, useEffect, useRef, useState} from "react";
import {GameContext} from "./Game";
import Position from "../model/Position";
import Direction from "../model/Direction";
import Walker from "./Walker";
import Spriter from "./Spriter";
import Dialog from "./Dialog";
import Item from "../model/Item";
import Npc from "../model/Npc";
import {LevelContext} from "./Level";

const Board = () => {

    console.log("BOARD")

    const {switchLevel, sprite} = useContext(GameContext)!;
    const {hero, npcs, doors, areas, getCoordinate} = useContext(LevelContext)!;

    const board = useRef<HTMLDivElement | null>(null);

    const [locked, setLocked] = useState<boolean>(false);
    const [walkingHero, setWalkingHero] = useState<Item | undefined>(hero);
    const [talkingNpc, setTalkingNpc] = useState<Npc | undefined>();

    if (!walkingHero) {
        return;
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (locked || talkingNpc) {
            return;
        }
        const newPosition = toPosition(event);
        if (walkingHero.position.samePosition(newPosition)) {
            return;
        }
        if (doors.some(door => door.position.samePosition(newPosition))) {
            setWalkingHero(walkingHero.withPosition(newPosition));
            return;
        }
        if (areas.some(area => area.isInside(newPosition))) {
            return;
        }
        setLocked(true);
        const npc = npcs.find(npc => npc.position.samePosition(newPosition));
        if (npc) {
            if (npc.engagingPosition.samePosition(walkingHero.position)) {
                setWalkingHero(walkingHero.withDirection(npc.engagingDirection));
                setTalkingNpc(npc)
                return;
            }
            setWalkingHero(walkingHero.withPosition(npc.engagingPosition).withDirection(npc.engagingDirection));
            return;
        }
        setWalkingHero(walkingHero.withPosition(newPosition).withDirection(Direction.BOTTOM));
    }

    const toPosition = (event: React.MouseEvent<HTMLDivElement>) => {
        const rect = board.current!.getBoundingClientRect();
        const boardPosX = event.clientX - rect.left;
        const boardPosY = event.clientY - rect.top;
        const col = getCoordinate(boardPosX);
        const row = getCoordinate(boardPosY);
        return new Position(col, row);
    }

    const onWalkFinish = () => {
        const talkingNpc = npcs.find(npc =>
            npc.engagingPosition.samePosition(walkingHero.position) && npc.engagingDirection === walkingHero.direction
        );
        if (talkingNpc) {
            setTalkingNpc(talkingNpc)
        }
        const door = doors.find(door => door.position.samePosition(walkingHero.position));
        if (door) {
            switchLevel(door.levelId);
        }
        setLocked(false);
    }

    const onDialogFinish = () => {
        setTalkingNpc(undefined);
        setWalkingHero(walkingHero.withDirection(Direction.BOTTOM));
    }

    return (
        <div className={"Board"} onClick={onClick} ref={board}>
            {npcs.map(((npc, index) => <Spriter key={index} item={npc}/>))}
            <Walker item={walkingHero} onWalkFinish={onWalkFinish}/>
            {talkingNpc && <Dialog npc={talkingNpc} onDialogFinish={onDialogFinish}/>}
        </div>
    );

}

export default Board;