class Status {

    private readonly _name: string;

    private constructor(name : string) {
        this._name = name;
    }

    public static IDLE =  new Status("idle");
    public static WALKING =  new Status("walking");

    public static VALUES = [Status.IDLE, Status.WALKING];

    get name(): string {
        return this._name;
    }

    static fromName(name: string): Status {
        const status = this.VALUES.find(status => status.name === name);
        if (!status) {
            throw new Error("Item status not found for " + name);
        }
        return status;
    }

}

export default Status;