class Avatar {

    private readonly _id: string;
    private readonly _name: string;
    private readonly _images: string[];

    private constructor(id: string, name: string, images: string[]) {
        this._id = id;
        this._name = name;
        this._images = images;
    }

    public static fromData(itemData: AvatarData): Avatar {
        return new Avatar(
            itemData.id,
            itemData.name,
            itemData.images
        );
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get images(): string[] {
        return this._images;
    }
}

export default Avatar;