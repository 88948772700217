import React, {createContext, CSSProperties, useEffect, useRef, useState} from "react";

interface BackgroundContextType {
    backgroundWidth: number,
    backgroundHeight: number,
}

export const BackgroundContext = createContext<BackgroundContextType | undefined>(undefined);

const Background = (props: any) => {

    const background = useRef<HTMLDivElement | null>(null);

    const [backgroundWidth, setBackgroundWidth] = useState<number | undefined>();
    const [backgroundHeight, setBackgroundHeight] = useState<number | undefined>();

    useEffect(() => {
        const parentWidth = background.current!.parentElement!.offsetWidth;
        const parentHeight = background.current!.parentElement!.offsetHeight;
        let backgroundWidth = parentWidth;
        let backgroundHeight = (parentWidth * 9) / 16;
        if (backgroundHeight > parentHeight) {
            backgroundHeight = parentHeight;
            backgroundWidth = (parentHeight * 16) / 9;
        }
        setBackgroundWidth(backgroundWidth);
        setBackgroundHeight(backgroundHeight);
    }, []);


    const style: CSSProperties = {
        width: backgroundWidth,
        height: backgroundHeight,
    };

    const backgroundContext: BackgroundContextType = {
        backgroundWidth: backgroundWidth!,
        backgroundHeight: backgroundHeight!
    };

    return (
        <div ref={background} className={"Background"} style={style}>
            <BackgroundContext.Provider value={backgroundContext}>
                {props.children}
            </BackgroundContext.Provider>
        </div>
    )

}

export default Background;