import React, {createContext, useEffect, useState} from "react";
import Fetcher from "../data/Fetcher";
import Sprite from "../model/Sprite";
import Images from "../model/Images";
import Avatar from "../model/Avatar";
import Level from "./Level";

interface GameContextType {
    levelId: string,
    switchLevel: (id: string) => void;
    getAvatar: (id: string) => Avatar;
    sprite: Sprite
}

export const GameContext = createContext<GameContextType | undefined>(undefined);

const Game = () => {

    const [gameData, setGameData] = useState<GameData | undefined>();
    const [sessionData, setSessionData] = useState<SessionData | undefined>();
    const [spriteData, setSpriteData] = useState<SpriteData | undefined>();
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

    const [levelId, setLevelId] = useState<string | undefined>();

    useEffect(() => {
        new Fetcher<GameData>("/data/game.json")
            .onSuccess((gameData: GameData) => setGameData(gameData))
            .fetch();
        new Fetcher<SessionData>("/data/session.json")
            .onSuccess((sessionData: SessionData) => {
                setSessionData(sessionData);
                setLevelId(sessionData.levelId);
            })
            .fetch();
        new Fetcher<SpriteData>("/data/sprite.json")
            .onSuccess((spriteData: SpriteData) => setSpriteData(spriteData))
            .fetch();
    }, []);

    //load all images
    useEffect(() => {
        if (!gameData || !sessionData) {
            return;
        }
        const images = Images.init(
            gameData.dialog.frameImage,
            gameData.dialog.nextImage,
            gameData.dialog.closeImage
        );
        //heroes
        sessionData.heroes.forEach(hero => hero.images.forEach(image => images.add(image)));
        //npcs
        gameData.npcs.map(npc => npc.images.forEach(image => images.add(image)));
        //load
        images.load().then(() => setImagesLoaded(true));

    }, [gameData, sessionData]);

    if (!gameData || !sessionData || !spriteData || !imagesLoaded || !levelId) {
        return;
    }

    const sprite = Sprite.fromData(spriteData);

    //avatars
    const avatars = new Map<string, Avatar>();
    sessionData.heroes.map(avatarData => avatars.set(avatarData.id, Avatar.fromData(avatarData)));
    gameData.npcs.map(avatarData => avatars.set(avatarData.id, Avatar.fromData(avatarData)));

    const switchLevel = (id: string): void => {
        console.log(id);
        setLevelId(id);
    }

    const getAvatar = (id: string): Avatar => {
        const avatar = avatars.get(id);
        if (!avatar) {
            throw new Error("Unable to find avatar with id " + id);
        }
        return avatar;
    }

    const gameContext = {
        levelId: levelId,
        switchLevel: switchLevel,
        getAvatar: getAvatar,
        sprite: sprite
    }

    return (
        <GameContext.Provider value={gameContext}>
            <Level/>
        </GameContext.Provider>
    );

}

export default Game;