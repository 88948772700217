import React, {useContext} from "react";
import Frame from "./Frame";
import Sprite from "../model/Sprite";
import Status from "../model/Status";
import Item from "../model/Item";
import {LevelContext} from "./Level";
import {GameContext} from "./Game";

interface ItemProps {
    item: Item
}

const Spriter = (props: ItemProps) => {

    const {getAvatar, sprite} = useContext(GameContext)!;
    const {getLeft, getTop} = useContext(LevelContext)!;

    const item = props.item;
    const images = getAvatar(item.id).images;

    //constants
    const left = getLeft(item.position);
    const top = getTop(item.position);

    return (
        <div className={"Item"} style={{
            left: left,
            top: top
        }}>
            <Frame images={images} status={Status.IDLE} direction={item.direction}/>
        </div>

    )
}

export default Spriter;