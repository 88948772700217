import React, {useContext, useEffect, useState} from "react";
import Sprite from "../model/Sprite";
import Status from "../model/Status";
import Direction from "../model/Direction";
import Images from "../model/Images";
import {GameContext} from "./Game";
import {LevelContext} from "./Level";

interface FrameProps {
    images: string[],
    status: Status,
    direction: Direction
}

const Frame = (props: FrameProps) => {

    const {sprite} = useContext(GameContext)!;
    const {avatarSize} = useContext(LevelContext)!;

    const sequence = sprite.getSequence(props.status, props.direction)

    const [spriteSequenceIndex, setSpriteSequenceIndex] = useState<number>(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSpriteSequenceIndex((prev) => {
                if (prev === sequence.length - 1) {
                    return 0;
                } else {
                    return prev + 1;
                }
            });
        }, sprite.frameTime);
        return () => clearInterval(intervalId);
    }, []);

    const spriteCol = sequence[spriteSequenceIndex].col;
    const spriteRow = sequence[spriteSequenceIndex].row;
    const spritePosX = spriteCol * sprite.baseWidth * avatarSize / sprite.baseWidth * (-1);
    const spritePosY = spriteRow * sprite.baseHeight * avatarSize / sprite.baseHeight * (-1);

    const imageStyle = (imageSrc: string) => {
        const htmlElement = Images.getInstance().get(imageSrc);
        const spriteWidth = htmlElement.width / sprite.baseWidth * avatarSize;
        const spriteHeight = htmlElement.height / sprite.baseHeight * avatarSize;
        return {
            width: avatarSize,
            height: avatarSize,
            backgroundImage: "url(" + imageSrc + ")",
            backgroundPositionX: spritePosX,
            backgroundPositionY: spritePosY,
            backgroundSize: spriteWidth + "px " + spriteHeight + "px",
        }
    }

    return props.images.map((imageSrc, index) => {
        return <div key={index} style={imageStyle(imageSrc)}/>
    })

}

export default Frame;